import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://fdffaa6ba757e35c9df83c724d865b60@o4507815293812736.ingest.us.sentry.io/4507815301021696",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});